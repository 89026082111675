import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  countryList: [],
  regionList: [],
  isDisable: true,
  isShow: true,


  userInfo: {},


  timer: null,

  country: "",
  region: "",

  payStatus: false,

  hppResponse: {},  // 支付数据  需要驳回给后台


  // 項目/活動/課程/会议----新流程的公用数据
  introduce: {},


  // 语言数据
  lang: {},

  // jci_project
  is_list: 'gallery',
  is_draft:'explore',
  pageState: 1,

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
